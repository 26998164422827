import style from "../src/style.module.css";
import css from "classnames";
import React from "react";
import { useState } from "react";
import styled from "styled-components";

export const Message = styled.p`
  font-family: 'VT323';
  color: black;
  margin-top:15px;
  font-size: 20px;
  font-weight: 400;

  line-height: 1.6;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 17px;
    margin-top:18px;
    margin-left: 10px;
    text-align: center;
   
  }
  transition: width 0.5s;;

  
`;

export default function Alert({ children, type, message }) {
  const [isShow, setIsShow] = useState(true);

  const renderElAlert = function () {
    return React.cloneElement(children);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsShow(false);
  };

  return (
    <div className={css(style.alert, style[type], !isShow && style.hide)}>
      <span className={style.closebtn} onClick={handleClose}>
        &times;
      </span>
      <Message> 
      {children ? renderElAlert() : message }
      </Message>
    </div>
  );
}