import React,{useState} from 'react'
import styled from 'styled-components'
import Butt from './Butt'





const Section = styled.section`
width: 95vw;

z-index:1000;




height: 60p;


font-size: ${props => props.theme.fontsm};

padding: 0.9rem 2.3rem;




`


const NavBar = styled.nav`

display: flex;
justify-content: space-between;
align-items: center;

width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

.mobile{
  display: none;
}

@media (max-width: 64em) {
.desktop{
  display: none;
}
.mobile{
  display: inline-block;
}

}


`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;


@media (max-width: 64em) {
/* 1024 px */



position: fixed;
top: 220px;
left: 0px;
right: 0;
bottom: 0;
width: 100vw;
height: ${props => `calc(100vh - ${props.theme.navHeight})`};
z-index:50;
background-color: ${props => `rgba(black},0.95)`};
backdrop-filter: blur(20px);

transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
transition: all 0.3s ease;
flex-direction: column;
justify-content: center;

touch-action: none;


}

`

const MenuItem = styled.li`
margin: 0 1rem;
color: Black;
font-size:0;
cursor: pointer;
font-family: "VT323";

&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 5px;
  background: lightblue;
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
}

@media (max-width: 64em) {
margin: 1rem 0;
font-size:20px;

&::after{
  display: none;
}

}
`


const HamburgerMenu = styled.span`
width:  ${props => props.click ? '2rem' : '1.5rem'};

height: 2px;
font-family: "";
background: black;

position: absolute;
top: 9.5rem;
left: 80%;
down: 0%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)'  };

display: none;
justify-content: center;
align-items: center;

cursor: pointer;
transition: all 0.3s ease;

@media (max-width: 64em) {
/* 1024 px */
display: flex;

}

&::after, &::before{
  content: ' ';
  width:  ${props => props.click ? '1rem' : '1.5rem'};
  height: 2px;
  right: ${props => props.click ? '-2px' : '0'};
background: black;
position: absolute;
transition: all 0.3s ease;

}

&::after{
  top: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'  };

}
&::before{
  bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'  };
}


`





const Navigation = () => {

  const [click, setClick] = useState(false);
  
const scrollTo = (id) => {

  let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })

  setClick(!click);
}

  return (
    
    <Section id="navigation">
      <NavBar>

        <HamburgerMenu  click={click}  onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo('Mint')}  >Mint</MenuItem>
          <MenuItem onClick={() => scrollTo('About')}  >About</MenuItem>
          <MenuItem onClick={() => scrollTo('Faq')}  >Faq</MenuItem>
        
        </Menu>
         

      </NavBar>
    </Section>
   
  )
}

export default Navigation